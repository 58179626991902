<template>
  <div class="MonitorListLoading">
    <monitor-placeholder v-for="placeholder in placeholders"
                         :key="placeholder.id" />
  </div>
</template>

<script>
import MonitorPlaceholder from './MonitorPlaceholder.vue'

export default {
  components: {
    MonitorPlaceholder
  },

  props: {
    numberOfPlaceholders: {
      required: false,
      type: Number,
      default: 3
    }
  },

  data () {
    return {
      placeholders: []
    }
  },

  created () {
    for (let i = 0; i < this.numberOfPlaceholders; i++) {
      this.placeholders.push({ id: i })
    }
  }
}
</script>

<style lang="scss" scoped>
  .MonitorListLoading {

  }
</style>
