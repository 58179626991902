import monitorsApi from '@/api/monitorsApi'

export default {
  data () {
    return {
      notSelectedMonitors: [],
      selectedMonitors: []
    }
  },
  methods: {
    async loadAllMonitors () {
      const response = await monitorsApi.get()
      const monitors = response.filter(monitor => !monitor.disabled)

      this.notSelectedMonitors = monitors.filter(monitor => {
        return this.selectedMonitors.findIndex(m => m.id === monitor.id) === -1
      })
    },

    async loadMonitors (search, take) {
      const monitors = await monitorsApi.search(search, take)
      this.notSelectedMonitors = monitors.filter(monitor => {
        return this.selectedMonitors.findIndex(m => m.id.toString() === monitor.id.toString()) === -1
      })
    },

    select (monitor) {
      this.selectedMonitors.push(monitor)

      this.notSelectedMonitors = this.notSelectedMonitors.filter(m => m.id.toString() !== monitor.id.toString())
      // let index = this.notSelectedMonitors.findIndex(m => m.id === monitor.id)
      //
      // this.notSelectedMonitors.splice(index, 1)
    },

    remove (monitor) {
      this.notSelectedMonitors.push(monitor)
      this.selectedMonitors = this.selectedMonitors.filter(m => m.id !== monitor.id)
      // let index = this.selectedMonitors.findIndex(m => m.id === monitor.id)
      // this.selectedMonitors.splice(index, 1)
    },

    mapItemForCard (monitor) {
      return {
        id: String(monitor.id),
        name: monitor.name,
        url: monitor.url,
        status: monitor.status
      }
    },

    isMonitorChecked (monitor) {
      return this.selectedMonitors.filter(m => m.id === monitor.id).length > 0
    }
  }
}
