<template>
  <div class="card card-monitor">
    <div class="card-body d-flex flex-row justify-content-between align-items-center">
      <div class="card-monitor-name-wrapper">
        <p class="card-monitor-name">
          {{ name || url }}
        </p>
        <p class="card-monitor-url">
          {{ url }}
        </p>
      </div>
      <div class="card-monitor-status" :class="{ active: status === 'online' }"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MonitorCard',
  props: {
    name: {
      type: String,
      default: ''
    },
    url: {
      type: String,
      default: ''
    },
    status: {
      type: String,
      default: ''
    }
  }
}
</script>

<style scoped lang="scss">
.card-monitor {
  box-shadow: 0 2px 4px 0 #19221D0D;

  & > .card-body {
    padding: 14px 20px;
    border-radius: 8px;
    border: 1px solid #E0E0E0;
    background: #F6F6F6;
    min-height: auto;

    &:hover {
      background: rgba(68, 143, 255, 0.1) !important;
      border-color: #70A9FF !important;
      cursor: grab;
    }
  }

  &-name-wrapper {
    display: flex;
    flex-direction: column;
    gap: 6px;
  }

  &-name {
    font-size: 14px;
    font-weight: 500;
    line-height: 125%;
    letter-spacing: 0.05px;
    color: #1B1823;
  }

  &-url {
    font-size: 11px;
    font-weight: 500;
    line-height: 125%;
    letter-spacing: 0.02px;
    color: #939394;
  }

  &-status {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: #BDBDBD;

    &.active {
      background: #27AE60;
    }
  }
}
</style>
